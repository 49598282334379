/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Card } from "primereact/card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Table } from "antd";
import moment from "moment";
import AuthLayout from "../layout/auth";
import { GetPurchaseHistory, GetUserById } from "../../Constants/Api/Api";
import "./PurchaseHistory.scss";

const PurchaseHistory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const [activeTab, setActiveTab] = useState("course");


  useLayoutEffect(() => {
    if(id){

      GetUserById(id)
        .then((res) => {
          setUserData(res.data.data);
        })
        .catch((err) => {});
    }
  }, [id]);

  const getData = async (type) => {
    try {
      const formData = { type };
      const response = await GetPurchaseHistory(formData);
      const dataWithIndex = response.data.data.map((item, index) => ({
        ...item,
        autoIncrementId: index + 1,
      }));
      setPurchaseData(dataWithIndex);
    } catch (error) {}
  };

  useEffect(() => {
    getData(activeTab);
  }, [activeTab]);

  const navigateToUser = () => {
    navigate("/dashboard");
  };

  const tabsContent = [
    { label: "Course", key: "course" },
    { label: "Test", key: "test" },
  ];

  const columnsCourse = [
    {
      title: "ID",
      dataIndex: "autoIncrementId",
      key: "id",
    },
    {
      title: "Course Name",
      dataIndex: ["purchase_history_course", "name"],
      key: "course_name",
    },
    {
      title: "Employee",
      dataIndex: ["history_user", "user_profile", "name"],
      key: "employee_name",
      render: (text) => text || "--",
    },
    {
      title: "Course Price",
      dataIndex: "amount",
      key: "course_price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },

    {
      title: "Purchase Date",
      dataIndex: "created_at",
      key: "date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Certificate URL",
      dataIndex: "certificate_url",
      key: "certificate_url",
      render: (certificate_url) =>
        certificate_url ? <a href={certificate_url}>View</a> : "--",
    },
  ];

  const columnsTest = [
    {
      title: "ID",
      dataIndex: "autoIncrementId",
      key: "id",
    },
    {
      title: "Test Name",
      dataIndex: ["purchase_history_test", "name"],
      key: "test_name",
    },
    {
      title: "Employee",
      dataIndex: ["history_user", "user_profile", "name"],
      key: "employee_name",
      render: (text) => text || "--",
    },
    {
      title: "Test Duration",
      dataIndex: ["purchase_history_test", "duration"],
      key: "test_duration",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },

    {
      title: "Purchase Date",
      dataIndex: "created_at",
      key: "date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Certificate URL",
      dataIndex: "certificate_url",
      key: "certificate_url",
      render: (certificate_url) =>
        certificate_url ? <a href={certificate_url}>View</a> : "--",
    },
  ];

  const handleTabSelect = (k) => {
    setActiveTab(k);
    getData(k);
  };

  return (
    <AuthLayout>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="30px"
        >
          <div>
            <h3 className="page-title">PURCHASE HISTORY</h3>
            <p className="page-sub-title">
              View Information related to Purchases
            </p>
          </div>
          <div>
            <Button
              icon="pi pi-arrow-left"
              severity="secondary"
              onClick={navigateToUser}
              style={{ borderRadius: "5px", height: "47px" }}
            >
              <span style={{ marginLeft: "5px" }}>Return to Dashboard</span>
            </Button>
          </div>
        </Box>

        <Form className="admin_details_form">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <Card
              style={{
                width: "100%",
                marginRight: "20px",
                marginBottom: "40px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "20px",
                  marginBottom: "40px",
                }}
              >
                <div>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "black",
                    }}
                  >
                    User's Name:
                  </h5>
                  <p>{userData?.user_profile?.name || "---"}</p>
                </div>
                <div>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "black",
                    }}
                  >
                    Email
                  </h5>
                  <p>{userData?.email || "---"}</p>
                </div>
                <div>
                  <h5
                    style={{
                      fontSize: "0.9rem",
                      fontFamily: "Cerebri Sans,sans-serif",
                      fontWeight: "700",
                      marginTop: "14px",
                      color: "black",
                    }}
                  >
                    Mobile:
                  </h5>
                  <p>{userData?.user_profile?.mobile || "---"}</p>
                </div>
              </div>
            </Card> */}
            <Card>
              <div className="admin_profile">
                <Form className="admin_details_form">
                  <div>
                    <h5 style={{ marginBottom: "20px" }}>
                      View all the purchase data associated with{" "}
                      {userData?.user_profile?.name}
                    </h5>
                    <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
                      {tabsContent.map((tab) => (
                        <Tab eventKey={tab.key} title={tab.label} key={tab.key}>
                          <div style={{ marginTop: "20px" }}>
                            <Table
                              columns={
                                activeTab === "course"
                                  ? columnsCourse
                                  : columnsTest
                              }
                              dataSource={purchaseData}
                              rowKey="autoIncrementId"
                            />
                          </div>
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </Form>
      </Box>
    </AuthLayout>
  );
};

export default PurchaseHistory;
